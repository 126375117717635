<template>
  <base-popup
    v-model="dialog"
    title="Добавить существующее курсы"
    :icon="UtilGetIcon('medexpert-book')"
    @save="$emit('save', selected)"
    :width="width"
    :height="height"
  >
    <list-course
      :value="dataSource"
      :showDelete="false"
      :notShowActions="['add']"
      :search.sync="search"
      :show-add-in-table="false"
      @input="(value) => (this.selected = value)"
      @onRefresh="init"
      :isSelect="true"
      :isTemplate="isTemplate"
    ></list-course>
  </base-popup>
</template>

<script>
import KnowledgeBaseService from "../../services/KnowledgeBaseService";
import ListEditMixin from "../../mixins/ListEditMixin";
import BasePopup from "../../layouts/BasePopup.vue";
import ListCourse from "./ListCourse.vue";

export default {
  name: "view-PopupCourse",
  components: {
    BasePopup,
    ListCourse,
  },
  mixins: [ListEditMixin],
  props: {
    value: Boolean,
    filter: Array,
    width: String,
    height: String,
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      apiService:
        KnowledgeBaseService[this.isTemplate ? "courseTemplate" : "course"],
      source: null,
      dataSource: [],
      selected: [],
      search: "",
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (value) {
          this.init();
        }
        this.search = null;
        this.$emit("input", value);
      },
    },
  },

  async mounted() {
    // await this.init();
  },
  methods: {
    init() {
      this.baseInit({ query: { done: false } });
    },
  },
};
</script>
